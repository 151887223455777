@use '../util' as *;

.missions-head{
    margin-top: rem(130);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        text-align: center;
        max-width: 80%;
        @include breakpoint-down(medium){
            max-width: 100%;
        }
    }
}

.missions-content{
    margin-left: 0;
    margin-right: 0;
    .cards{
        display: flex;
        flex-direction: row;
        @include breakpoint-down(medium){
            flex-direction: column;
        }
    }
}