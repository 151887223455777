html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  font-family: "Bungee", cursive;
  background-color: #141414;
}

section {
  margin: 7%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1440px) {
  section {
    margin: 7% 20%;
  }
}

header {
  padding: 0 7%;
  display: flex;
  flex-direction: row;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #141414;
  top: 0;
  position: fixed;
  z-index: 2;
}
@media (min-width: 1440px) {
  header {
    padding: 0 20%;
  }
}
header .open span:first-child {
  transform: translateY(0.25rem);
  transform: rotate(45deg);
}
header .open span:nth-child(2) {
  opacity: 0;
}
header .open span:last-child {
  transform: rotate(-45deg);
}
header a {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
header .hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
@media (min-width: 1024px) {
  header .hamburger {
    display: none;
  }
}
header .hamburger span {
  height: 0.125rem;
  width: 1.375rem;
  background-color: #FDF9F2;
  transition: all 300ms ease-in-out;
  transform-origin: left center;
}
header .hamburger span:not(:last-child) {
  margin-bottom: 0.375rem;
}
header .nav {
  transition: all 300ms ease-in-out;
}
@media (max-width: 1023px) {
  header .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    position: absolute;
    top: 5rem;
    right: -100vw;
  }
}
header .nav.open {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-color: #D9310B;
  z-index: -1;
  position: absolute;
  transform: translateX(-rem(1415));
  right: 0;
}
header .nav ul {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  header .nav ul {
    flex-direction: column;
  }
}
header .nav ul li {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  list-style: none;
}
@media (max-width: 1023px) {
  header .nav ul li {
    font-family: "Bungee", cursive;
    font-size: 1.25rem;
    list-style: none;
  }
}
header .nav ul li:not(:last-child) {
  margin-right: 2.1875rem;
}
@media (max-width: 1023px) {
  header .nav ul li:not(:last-child) {
    margin-right: 0;
  }
}
header .nav ul li a {
  transition: all 0.3s ease-in-out;
  color: #FDF9F2;
  text-decoration: none;
}
header .nav ul li a:hover {
  color: #D9310B;
}
@media (max-width: 1023px) {
  header .nav ul li a:hover {
    color: #141414;
  }
}
header .nav ul li a.checked {
  color: #D9310B;
  font-weight: 700;
}
@media (max-width: 1023px) {
  header .nav ul li a.checked {
    color: #141414;
  }
}

footer {
  margin: 6.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1023px) {
  footer {
    flex-direction: column;
  }
}
footer .logo {
  margin: 0;
  margin-right: 10%;
}
@media (max-width: 1023px) {
  footer .logo {
    margin-right: 0;
    margin-bottom: 1.5625rem;
  }
}
footer .logo p {
  color: #8C2D18;
  text-decoration: underline;
}
@media (max-width: 1023px) {
  footer .logo p {
    font-size: 0.875rem;
  }
}
footer .logo a {
  top: 0;
}
@media (max-width: 1023px) {
  footer .logo img {
    width: 11.25rem;
    height: auto;
  }
}
footer .footer-nav ul {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1023px) {
  footer .footer-nav ul {
    padding-left: 0;
  }
}
footer .footer-nav ul li {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  list-style: none;
}
footer .footer-nav ul li:not(:last-child) {
  margin-bottom: 1.25rem;
}
footer .footer-nav ul li a {
  color: #FDF9F2;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
footer .footer-nav ul li a:hover {
  color: #D9310B;
}
footer .footer-nav ul li a .checked {
  color: #D9310B;
  font-weight: 700;
}

@media (max-width: 1439px) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-for-desktop {
    display: none;
  }
}

h1, h2, h3 {
  font-family: "Bungee", cursive;
  color: #FDF9F2;
  margin: 0;
  pointer-events: none;
}

h1 {
  font-size: 4rem;
  line-height: 4.8125rem;
  margin-bottom: 0.9375rem;
}
h1 span {
  color: #D9310B;
}
@media (max-width: 1023px) {
  h1 {
    font-size: 2.8125rem;
    margin-bottom: 0.5625rem;
    line-height: 3.375rem;
  }
}
@media (max-width: 639px) {
  h1 {
    font-size: 2.1875rem;
    margin-bottom: 0.5625rem;
    line-height: 2.625rem;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 3.125rem;
}
h2.text {
  margin: 0;
  margin-bottom: 0.9375rem;
}
@media (max-width: 1023px) {
  h2.text {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 639px) {
  h2.text {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 1023px) {
  h2 {
    font-size: 1.875rem;
    margin-bottom: 1.5625rem;
    line-height: 2.25rem;
  }
}
@media (max-width: 639px) {
  h2 {
    font-size: 1.375rem;
    margin-bottom: 1.5625rem;
    line-height: 1.625rem;
  }
}

h3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 0.9375rem;
  color: #8C2D18;
}
@media (max-width: 1023px) {
  h3 {
    font-size: 1.375rem;
    margin-bottom: 0.9375rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 639px) {
  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.9375rem;
    line-height: 1.375rem;
  }
}

p {
  font-family: "Barlow", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: #FDF9F2;
  line-height: 1.8125rem;
  margin: 0;
  pointer-events: none;
}
@media (max-width: 1023px) {
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 639px) {
  p {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}

a, a:visited, a:active {
  text-decoration: none;
}

.div-button {
  margin-top: 2.8125rem;
}

.button-large {
  transition: all 0.3s ease-in-out;
  font-family: "Bungee", cursive;
  font-size: 1.375rem;
  color: #141414;
  padding: 0rem 5rem;
  background-color: #D9310B;
}
.button-large:hover {
  opacity: 70%;
}
@media (max-width: 1023px) {
  .button-large {
    font-size: 1.125rem;
    padding: 0.625rem 2.8125rem;
  }
}
@media (max-width: 639px) {
  .button-large {
    font-size: 0.875rem;
    padding: 0.625rem 2.8125rem;
  }
}
.button-unfilled-red {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.9375rem;
}
.button-unfilled-red p {
  font-weight: 600;
  margin-right: 0.9375rem;
  color: #8C2D18;
}
@media (max-width: 1023px) {
  .button-unfilled-red p {
    white-space: nowrap;
  }
}
.button-unfilled-red svg path {
  stroke: #8C2D18;
}
.button-unfilled-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
}
.button-unfilled-white p {
  margin: 0;
  font-weight: 600;
  margin-right: 0.9375rem;
  color: #FDF9F2;
}
@media (max-width: 1023px) {
  .button-unfilled-white p {
    white-space: nowrap;
  }
}

.card-wrap-photo-bg {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.card-wrap-photo-bg:hover .card-photo-bg {
  transform: scale(1.15);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1024px) {
  .card-wrap-photo-bg:hover .card-photo-bg {
    padding-left: 10%;
  }
}
.card-wrap-photo-bg:hover .card-side-to-side {
  transform: scale(1.15);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1440px) {
  .card-wrap-photo-bg:hover .card-side-to-side {
    padding-left: 10%;
  }
}
.card-photo-bg {
  width: 100%;
  height: 19.6875rem;
  padding: 0;
  padding-left: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
}
.card-photo-bg h3 {
  color: #FDF9F2;
}
.card-photo-bg p {
  max-width: 50%;
}
@media (max-width: 1023px) {
  .card-photo-bg p {
    max-width: 80%;
  }
}
@media (max-width: 639px) {
  .card-photo-bg p {
    max-width: 100%;
  }
}
@media (max-width: 1023px) {
  .card-photo-bg {
    text-align: center;
    align-items: center;
    padding: 5rem 1.25rem;
  }
}
.card-text {
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 19.6875rem;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.card-text:hover {
  transform: translateX(10%);
}
@media (max-width: 1023px) {
  .card-text {
    margin-bottom: 0;
    height: 15.625rem;
  }
}
.card-text div {
  max-width: 45%;
}
.card-text .image-wrap {
  height: 100%;
  width: 45%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card-text .image-wrap.no-bg {
  background-size: contain;
  background-repeat: no-repeat;
}
.card-side-to-side {
  transition: all 0.3s ease-in-out;
  height: 25rem;
  padding: 0 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
}
@media (min-width: 640px) {
  .card-side-to-side p {
    width: 60%;
  }
}
@media (min-width: 1024px) {
  .card-side-to-side p {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .card-side-to-side {
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 3.75rem 1.25rem;
  }
}

.blur {
  max-width: 31.25rem;
  padding: 2.5rem 3.75rem;
  background-color: rgba(20, 20, 20, 0.7);
  backdrop-filter: blur(0.25rem);
  display: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.blur .p-text {
  max-width: 23.75rem;
  text-align: center;
}

.threejs {
  width: 60%;
  height: 100%;
  margin: 0;
  margin-top: 5rem;
  margin-left: 40%;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .threejs {
    margin-bottom: 3.125rem;
    width: 100%;
    margin-left: 0;
    height: 80vh;
  }
}
@media (max-width: 639px) {
  .threejs {
    margin-bottom: 1.5625rem;
    height: 31.25rem;
  }
}
@media (min-width: 1440px) {
  .threejs {
    margin-right: 20%;
  }
}
@media (max-width: 1023px) {
  .threejs canvas {
    margin-bottom: 3.125rem;
    width: 100%;
    margin-left: 0;
    height: 80vh;
  }
}

.head {
  background-color: black;
  margin: 0;
  height: 80vh;
  top: 11.125rem;
}
@media (max-width: 1023px) {
  .head {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
.head .content {
  position: absolute;
  margin-left: 7%;
  max-width: 50%;
}
@media (min-width: 1440px) {
  .head .content {
    margin-left: 20%;
  }
}
.head .content p {
  pointer-events: none;
  max-width: 80%;
}
.head .content h1 {
  pointer-events: none;
}
@media (max-width: 1023px) {
  .head .content {
    margin-right: 7%;
    margin-bottom: 7%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.missions .mission:first-child {
  margin-bottom: 2.5rem;
}
@media (max-width: 1023px) {
  .missions .mission:first-child {
    margin-bottom: 1.25rem;
  }
}

.moons .card-text:first-child {
  margin-bottom: 2.5rem;
}
@media (max-width: 1023px) {
  .moons .card-text:first-child {
    margin-bottom: 0;
  }
}

.gallery {
  margin: 0;
  margin-top: 6.25rem;
  padding: 5.625rem 0;
  background-image: url("/img/mars_near.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery:hover .content.blur {
  transform: scale(1.15);
}

.rovers .card-text:not(:last-child), .orbiters .card-text:not(:last-child) {
  margin-bottom: 2.5rem;
}

@media (min-width: 640px) {
  .sounds h2 {
    text-align: left;
  }
}
.sounds .image-wrap {
  background-image: url("/img/Sounds-of-Mars_birds_Mars_Moment.jpg");
}

.missions-head {
  margin-top: 8.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.missions-head p {
  text-align: center;
  max-width: 80%;
}
@media (max-width: 1023px) {
  .missions-head p {
    max-width: 100%;
  }
}

.missions-content {
  margin-left: 0;
  margin-right: 0;
}
.missions-content .cards {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1023px) {
  .missions-content .cards {
    flex-direction: column;
  }
}

.mission-head {
  margin: 0;
  margin-left: 0;
  margin-top: 5rem;
  width: 100%;
  padding-left: 7%;
  padding-right: 7%;
  height: 40.625rem;
  background-image: url("/img/Perseverance_card_bg.jpg");
  background-position: center;
  background-size: cover;
}
@media (min-width: 1440px) {
  .mission-head {
    padding-left: 20%;
    padding-right: 20%;
  }
}
.mission-head .content {
  max-width: 60%;
}
.mission-head .content p {
  max-width: 80%;
}
@media (max-width: 1023px) {
  .mission-head .content {
    margin-right: 7%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.mission-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mission-stats .stat {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 18.75rem;
}
@media (max-width: 1023px) {
  .mission-stats .stat:not(:last-child) {
    margin-bottom: 1.5625rem;
  }
}
@media (max-width: 639px) {
  .mission-stats .stat {
    max-width: 80%;
  }
  .mission-stats .stat:not(:last-child) {
    margin-bottom: 0.9375rem;
  }
}
@media (max-width: 1023px) {
  .mission-stats {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.facts {
  display: flex;
  flex-direction: row;
  margin-left: 0;
}
.facts .img-wrap {
  height: auto;
  width: 50%;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .facts .img-wrap {
    z-index: -1;
    opacity: 60%;
    width: 60%;
  }
}
.facts .facts-content {
  margin-left: 10%;
}
@media (max-width: 1023px) {
  .facts .facts-content {
    width: 60%;
    margin-left: -20%;
  }
}
.facts .facts-content p span {
  color: #D9310B;
  font-weight: 700;
}
@media (max-width: 1023px) {
  .facts {
    align-items: flex-end;
    justify-content: center;
  }
}

@media (max-width: 1023px) {
  h2 {
    margin-bottom: 3.125rem;
    text-align: center;
  }
}
@media (max-width: 639px) {
  h2 {
    margin-bottom: 1.5625rem;
  }
}

@media (min-width: 1024px) {
  .program {
    width: 50%;
  }
}
.program p:not(:last-child) {
  margin-bottom: 1.8125rem;
}
.program ul li {
  font-family: "Barlow", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: #FDF9F2;
  line-height: 1.8125rem;
  margin: 0;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .program ul li {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 639px) {
  .program ul li {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-bottom: 1.375rem;
  }
}
.program ul li:not(:last-child) {
  margin-bottom: 1.8125rem;
}

.content {
  margin-top: 3.125rem;
}
@media (max-width: 1023px) {
  .content {
    margin-top: 1.5625rem;
  }
}
.content .tab-content {
  display: none;
}
.content .tab-content.checked {
  display: block;
}

.videos {
  display: flex;
  height: auto;
  position: relative;
}
.videos video {
  width: 50%;
}
@media (max-width: 639px) {
  .videos video {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .videos {
    flex-direction: column;
  }
}

.tabs {
  display: flex;
  justify-content: center;
}
.tabs ul {
  list-style: none;
  display: flex;
  color: #FDF9F2;
  font-family: "Barlow", sans-serif;
  font-size: 1.125rem;
  line-height: 1.8125rem;
}
.tabs ul li {
  cursor: pointer;
}
.tabs ul li:not(:last-child) {
  margin-right: 1.875rem;
}
.tabs ul li.checked {
  color: #D9310B;
  font-weight: 700;
}
@media (max-width: 1023px) {
  .tabs ul li {
    font-size: 1rem;
    line-height: 1.5rem;
    white-space: nowrap;
  }
}
@media (max-width: 639px) {
  .tabs ul li {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}
@media (max-width: 1023px) {
  .tabs ul {
    width: 100%;
    overflow-x: scroll;
    padding: 0;
  }
  .tabs ul::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 1023px) {
  .tabs {
    width: 100%;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}

.science {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.science h1 {
  text-align: center;
  margin-bottom: 3.125rem;
}
@media (min-width: 1024px) {
  .science h1 {
    max-width: 60%;
  }
}
@media (min-width: 1024px) {
  .science p {
    max-width: 60%;
  }
}
.science p:not(:last-child) {
  margin-bottom: 0.9375rem;
}

.overlays {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
}
.overlays .overlay {
  padding-left: 0.9375rem;
  width: 50%;
  background-color: rgba(14, 14, 14, 0.7);
  backdrop-filter: blur(4);
}
@media (max-width: 639px) {
  .overlays .overlay {
    display: grid;
    width: 100%;
    height: 1.375rem;
  }
}
@media (max-width: 639px) {
  .overlays {
    display: grid;
    height: 100%;
  }
}

.accordion_item {
  margin-bottom: 1%;
  background: #202020;
  padding: 1% 3.5%;
}

.accordion_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.accordion_header h3 {
  margin-bottom: 0;
  transition: color 0.3s ease-in-out;
}
.accordion_header.open h3 {
  color: #D9310B;
}
.accordion_header.open img {
  transform: rotate(180deg);
}

.accordion_content {
  transition: height 0.3s ease-in-out;
}
.accordion_content.collapsing {
  height: 0;
  overflow: hidden;
}
.accordion_content.collapse {
  display: none;
}
.accordion_content.open {
  display: block;
}
.accordion_content p:first-child {
  margin-top: 1%;
}
.accordion_content p {
  margin-bottom: 1%;
}

img.collapse_arrow {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 0.9375rem;
}
@media (max-width: 1023px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1023px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.gallery-container .gallery-card {
  height: 19.6875rem;
  width: 100%;
  position: relative;
}
.gallery-container .gallery-card img {
  width: 100%;
  height: 19.6875rem;
  object-fit: cover;
}
.gallery-container .gallery-card .gallery-card-overlay {
  width: 100%;
  background: rgba(20, 20, 20, 0.7);
  backdrop-filter: blur(0.25rem);
  position: absolute;
  bottom: 0;
  padding: 0 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gallery-container .gallery-card svg {
  margin: 0;
  padding: 0;
}
.gallery-container .gallery-card a {
  display: grid;
  place-items: center;
}

table {
  color: #D9310B;
  border: none;
}
table tr:nth-child(2n+1) {
  background: #8C2D18;
  color: #FDF9F2;
}
table td {
  padding: 0.3125rem 0.9375rem;
}
table:not(:last-child) {
  margin-bottom: 7%;
}
table:last-child tr:nth-child(2n) {
  background: #8C2D18;
  color: #FDF9F2;
}
table:last-child tr:nth-child(2n+1) {
  background: #141414;
  color: #FDF9F2;
}

.value {
  color: #FDF9F2;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  line-height: 1.8125rem;
}

.rover_content p {
  margin-bottom: 1.5625rem;
}
.rover_content p.last {
  margin-bottom: 3.125rem;
}

ul {
  color: #FDF9F2;
  font-family: "Barlow", sans-serif;
  line-height: 1.8125rem;
}

p.no-m-b {
  margin-bottom: 0.3125rem;
}

p.last {
  margin-bottom: 3.125rem;
}