@use '../util' as *;

.accordion_item{
    margin-bottom: 1%;
    background:#202020 ;
    padding:1% 3.5%;
}

.accordion_header{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h3{
        margin-bottom: 0;
        transition: color 0.3s ease-in-out;
    }
    &.open{
        h3{
            color:$accent;
        }
        img{
            transform: rotate(180deg);
        }
    }
}

.accordion_content{
    
    &.collapsing{
        height: 0;
        overflow: hidden;
    }
    &.collapse{
        display: none;

    }
    &.open{
        display: block;
    }
    p:first-child{
        margin-top: 1%;
    }
    p{
        margin-bottom: 1%;
    }
    transition: height 0.3s ease-in-out;
}

img.collapse_arrow{
    width:rem(24);
    height:rem(24);
    transition: all 0.3s ease-in-out;

}