@use '../util' as *;

.threejs{
    
    width: 60%;
    height: 100%;
    margin: 0;
    margin-top: rem(80);
    margin-left: 40%;
    overflow: hidden;
    @include breakpoint-down(medium){
        margin-bottom: rem(50);
        width: 100%;
        margin-left: 0;
        height: 80vh
    }
    @include breakpoint-down(small){
        margin-bottom: rem(25);
        height: rem(500);
    }
    @include breakpoint-up(xlarge){
        margin-right: 20%;
    }

    canvas{
        @include breakpoint-down(medium){
            margin-bottom: rem(50);
            width: 100%;
            margin-left: 0;
            height: 80vh;
        }
       
    }
    
}

.head{
    background-color: black;
    margin: 0;
    height: 80vh;
    // margin-top: rem(80);
    // z-index:1;
    // position: absolute;
    top:rem(178);
    @include breakpoint-down(medium){
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    
    .content{
        position:absolute;
        margin-left: 7%;
        max-width: 50%;
        @include breakpoint-up(xlarge){
            margin-left: 20%;
        }
        p{
            pointer-events: none;
            max-width: 80%;
        }
        h1{
            pointer-events: none;
        }
        @include breakpoint-down(medium){
            margin-right: 7%;
            margin-bottom: 7%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: center;
            position:relative;
        }
    } 
}


.missions{
    .mission:first-child{
        margin-bottom: rem(40);
        @include breakpoint-down(medium){
            margin-bottom: rem(20);
        }
    }
}

.moons{
    .card-text:first-child{
        margin-bottom: rem(40);
        @include breakpoint-down(medium){
            margin-bottom: 0;
        }
    }
    
}

.gallery{
    margin: 0;
    margin-top: rem(100);
    padding: rem(90) 0;
    background-image: url('/img/mars_near.jpg');
    background-size: cover;
    display:flex;
    align-items: center;
    justify-content: center;
    &:hover{
        .content.blur{
            transform: scale(1.15);
        }
    }
}

.rovers, .orbiters{
    .card-text:not(:last-child){
        margin-bottom: rem(40);
    }
}


.sounds{
    h2{
        @include breakpoint-up(medium){
            text-align: left;
        }
    }
    .image-wrap{
        background-image: url('/img/Sounds-of-Mars_birds_Mars_Moment.jpg');
    }
}